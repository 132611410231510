import React, { useState, useEffect } from 'react';
import {
    TextField,
    FormControl,
    FormLabel,
    Paper,
} from '@mui/material';

function AddressInput({ address, onChange }) {
    const [value, setValue] = useState(address);

    useEffect(() => {
        if (onChange) {
            onChange(value);
        }
    }, [value, onChange]);

    return (
        <Paper elevation={0}>
            <FormControl fullWidth>
                <FormLabel id="address">Address</FormLabel>
            </FormControl>
            <TextField
                id="street1"
                label="Street1"
                fullWidth
                value={value.street1}
                onChange={(e) => { setValue({ ...value, street1: e.target.value }) }}
                margin="dense"
            />
            <TextField
                id="street2"
                label="Street2"
                fullWidth
                value={value.street2}
                onChange={(e) => { setValue({ ...value, street2: e.target.value }) }}
                margin="dense"
            />
            <TextField
                id="city"
                label="City"
                fullWidth
                value={value.city}
                onChange={(e) => { setValue({ ...value, city: e.target.value }) }}
                margin="dense"
            />
            <TextField
                id="state"
                label="State"
                fullWidth
                value={value.state}
                onChange={(e) => { setValue({ ...value, state: e.target.value }) }}
                margin="dense"
            />
            <TextField
                id="zip"
                label="Zip"
                fullWidth
                value={value.zip}
                onChange={(e) => { setValue({ ...value, zip: e.target.value }) }}
                margin="dense"
            />
        </Paper>
    );
}

export default AddressInput;

import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Formatter from '../../../utils/Formatter';
import { PAYTYPES } from '../../../store/employeeSlice';
import { Typography } from '@mui/material';

function TimesheetEmployeeReviewList({ payPeriod, employees = [], onEmployeeSelected }) {
    const { employeeRecords } = payPeriod;

    const handleEmployeeSelected = (employee) => () => {
        if (onEmployeeSelected) {
            onEmployeeSelected(employee);
        }
    };

    const renderLabelValue = ({ label, value }) => (
        <Typography
            key={label}
            sx={{ display: 'block' }}
            component="span"
            variant="body2"
        >
            {label}: {value}
        </Typography>
    );

    const renderEmployeeRecord = (employeeRecord) => {
        const { employee, duration, payType, rates } = employeeRecord;
        const payment = (payType === PAYTYPES.salary) ? employeeRecord.salary : (
            (duration.regular * rates) + (duration.overtime * rates * 1.5) + (duration.overtime2 * rates * 2)
        ) / 60;

        const labelValues = [];
        if (employeeRecord.payType === PAYTYPES.salary) {
            labelValues.push({ label: 'Salary', value: employeeRecord.salary });
        }
        if (employeeRecord.payType === PAYTYPES.hour) {
            labelValues.push({ label: 'Rate/Hour', value: employeeRecord.rates });

            if (employeeRecord.duration.regular) {
                labelValues.push({ label: 'Hours / 2 weeks', value: Formatter.minutesToHours(employeeRecord.duration.regular) });
            }
            if (employeeRecord.duration.overtime) {
                labelValues.push({ label: 'Overtime', value: Formatter.minutesToHours(employeeRecord.duration.overtime) });
            }
            if (employeeRecord.duration.overtime2) {
                labelValues.push({ label: 'Overtime 2X', value: Formatter.minutesToHours(employeeRecord.duration.overtime2) });
            }
        }
        labelValues.push({ label: 'Tip', value: employeeRecord.tip });
        if (employeeRecord.ira) {
            labelValues.push({ label: 'Simple IRA', value: employeeRecord.ira });
            labelValues.push({ label: 'Match', value: Math.min(employeeRecord.ira, payment * 0.03) });
        }
        labelValues.push({ label: 'Payment', value: payment });

        return (
            <ListItem
                key={employee._id}
                divider
                dense
                disableGutters
                secondaryAction={
                    <IconButton
                        edge="end"
                        aria-label="Edit"
                        onClick={handleEmployeeSelected(employee)}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                }
            >
                <ListItemButton
                    disableGutters
                    onClick={handleEmployeeSelected(employee)}
                >
                    <ListItemText
                        primary={`${employee.employeeId}: ${Formatter.formatEmployeeName(employee)}`}
                        secondary={
                            labelValues.map(labelValue => renderLabelValue(labelValue))
                        }
                    />
                </ListItemButton>
            </ListItem>
        );
    };

    const summaryPayment = useMemo(() => (
        payPeriod.employeeRecords
            .map(employeeRecord => (
                (employeeRecord.payType === PAYTYPES.salary) ? employeeRecord.salary : (
                    (employeeRecord.duration.regular * employeeRecord.rates)
                    + (employeeRecord.duration.overtime * employeeRecord.rates * 1.5)
                    + (employeeRecord.duration.overtime2 * employeeRecord.rates * 2)
                )
            ))
            .reduce((acc, pay) => acc + pay, 0)
    ), [payPeriod]);

    const renderSummary = () => {
        return (
            <ListItem
                dense
                disableGutters
            >
                <ListItemText
                    primary={`Total: ${summaryPayment}`}
                />
            </ListItem>
        );
    }

    return (
        <List disablePadding>
            {employeeRecords.map(employeeRecord => renderEmployeeRecord(employeeRecord))}
            {renderSummary()}
        </List>
    );
}

export default TimesheetEmployeeReviewList;

import { configureStore } from '@reduxjs/toolkit';
import employeeReducer from './employeeSlice';
import payPeriodReducer from './payPeriodSlice';

export const store = configureStore({
    reducer: {
        employee: employeeReducer,
        payPeriod: payPeriodReducer,
    },
});

import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import TimesheetAPI from '../api/timesheetAPI';
import Formatter from '../utils/Formatter';

const DAILY_OVERTIME2_LIMIT = 12 * 60;
const DAILY_OVERTIME_LIMIT = 8 * 60;
const WEEKLY_OVERTIME_LIMIT = 40 * 60;

export const PAY_PERIOD_STATUSES = {
    inProgress: 'in-progress',
    done: 'done'
};

export const NEW_PAY_PERIOD_TEMPLATE = {
    fromDate: null,
    toDate: null,
    payrollDate: null,
    status: PAY_PERIOD_STATUSES.inProgress,
    employeeRecords: [],
    content: '',
};

export const EMPTY_EMPLOYEE_RECORD = {
    duration: {
        regular: 0,
        overtime: 0,
        overtime2: 0
    },
    tip: 0
};

const initialState = {
    entities: [],
    entityDetail: null,
    employeeRecord: null,
    timeRecord: null,
    status: 'idle',
    currentRequestId: undefined,
    error: null,
};

export const fetchPayPeriodList = createAsyncThunk('payPeriod/fetchPayPeriodList', async ({ filter, sort }) => {
    const response = await TimesheetAPI.fetchPayPeriods({ filter, sort });
    const result = await response.json();
    if (!result.success) {
        return isRejectedWithValue(result);
    }
    return result.data;
});

export const fetchPayPeriodById = createAsyncThunk('payPeriod/fetchPayPeriodById', async ({ id }) => {
    const response = await TimesheetAPI.fetchPayPeriodById(id);
    const result = await response.json();
    if (!result.success) {
        return isRejectedWithValue(result);
    }
    return result.data;
});

export const createPayPeriod = createAsyncThunk('payPeriod/createPayPeriod', async ({ fromDate, toDate, payrollDate }) => {
    const newItem = {
        ...NEW_PAY_PERIOD_TEMPLATE,
        title: Formatter.formatLongDate(toDate),
        fromDate,
        toDate,
        payrollDate,
    };

    const response = await TimesheetAPI.createPayPeriod(newItem);
    const result = await response.json();
    if (!result.success) {
        return isRejectedWithValue(result);
    }
    return result.data;
});

export const updatePayPeriod = createAsyncThunk('payPeriod/updatePayPeriod', async ({ data }) => {
    const response = await TimesheetAPI.updatePayPeriod(data);
    const result = await response.json();
    if (!result.success) {
        return isRejectedWithValue(result);
    }
    return result.data;
});

export const deletePayPeriod = createAsyncThunk('payPeriod/deletePayPeriod', async ({ id }) => {
    await TimesheetAPI.deletePayPeriod(id);
    return id;
});

const calculateEmployeeSummary = (fromDate, times) => {
    let tip = times.reduce((acc, time) => acc + time.tip, 0);

    let calcTimes = _.chain(times)
        .map(time => ({
            day: moment(time.inTime).hours(0).minutes(0).diff(fromDate, 'days'),
            week: moment(time.inTime).hours(0).minutes(0).diff(fromDate, 'weeks'),
            durationMinutes: time.durationMinutes,
        }))
        .groupBy(value => value.day)
        .map((value) => {
            const total = _.sumBy(value, 'durationMinutes');
            let regular = total;
            let overtime = 0;
            let overtime2 = 0;

            if (total > DAILY_OVERTIME2_LIMIT) {
                overtime2 = total - DAILY_OVERTIME2_LIMIT;
                overtime = DAILY_OVERTIME2_LIMIT - DAILY_OVERTIME_LIMIT;
                regular = DAILY_OVERTIME_LIMIT;
            } else if (total > DAILY_OVERTIME_LIMIT) {
                overtime = total - DAILY_OVERTIME_LIMIT;
                regular = DAILY_OVERTIME_LIMIT;
            }

            return {
                day: value[0].day,
                week: value[0].week,
                total,
                regular,
                overtime,
                overtime2,
                value
            }
        })
        .sortBy(value => value.day)
        .groupBy(value => value.week)
        .map((value) => {
            const total = _.sumBy(value, 'total');
            let regular = _.sumBy(value, 'regular');
            let overtime = _.sumBy(value, 'overtime');
            let overtime2 = _.sumBy(value, 'overtime2');

            // special case for seventh consecutive day of work in a workweek
            if (value.length === 7) {
                overtime2 += value[6].overtime;
                overtime -= value[6].overtime;

                overtime += value[6].regular;
                regular -= value[6].regular;
            }

            if (regular > WEEKLY_OVERTIME_LIMIT) {
                overtime += regular - WEEKLY_OVERTIME_LIMIT;
                regular = WEEKLY_OVERTIME_LIMIT;
            }

            return {
                total,
                regular,
                overtime,
                overtime2,
                value
            }
        })
        .reduce((acc, value) => ({
            total: acc.total + value.total,
            regular: acc.regular + value.regular,
            overtime: acc.overtime + value.overtime,
            overtime2: acc.overtime2 + value.overtime2,
            value: [ ...acc.value, ...value.value ],
        }), ({ total: 0, regular: 0, overtime: 0, overtime2: 0, value: []}))
        .value();

        console.log('calcTimes: ', calcTimes);

    return ({
        duration: calcTimes,
        tip
    });
};

export const payPeriodSlice = createSlice({
    name: 'payPeriod',
    initialState,
    reducers: {
        fetchEmployeeRecord(state, action) {
            const payPeriod = state.entityDetail;
            const { employee } = action.payload;
            let employeeRecord = payPeriod.employeeRecords.find(record => record.employee._id === employee._id);
            if (!employeeRecord) {
                employeeRecord = {
                    employee,
                    payType: employee.payType,
                    salary: employee.salary,
                    rates: employee.rates,
                    ira: employee.ira,
                    duration: {
                        total: 0,
                        regular: 0,
                        overtime: 0,
                        overtime2: 0
                    },
                    tip: 0,
                    times: []
                };
                payPeriod.employeeRecords = [...payPeriod.employeeRecords, employeeRecord];
            }
            state.entityDetail = payPeriod;
            state.employeeRecord = employeeRecord;
        },
        fetchTimeRecord(state, action) {
            state.timeRecord = action.payload.time;
        },
        addTimeRecord(state, action) {
            const inTime = moment(action.payload.inTime);
            const outTime = moment(action.payload.outTime);
            const breakMinutes = 0;
            const duration = moment.duration(outTime.diff(inTime)).subtract(breakMinutes, 'minutes');
            const time = {
                timeId: uuidv4(),
                inTime: inTime.toISOString(),
                outTime: outTime.toISOString(),
                breakMinutes: 0,
                durationMinutes: duration.asMinutes(),
                tip: 0,
            };
            state.timeRecord = time;
            const times = [...state.employeeRecord.times, time];
            const summary = calculateEmployeeSummary(state.entityDetail.fromDate, times);
            const employeeRecord = {
                ...state.employeeRecord,
                ...summary,
                times
            };
            state.employeeRecord = employeeRecord;
            state.entityDetail = {
                ...state.entityDetail,
                employeeRecords: state.entityDetail.employeeRecords.map(item => item.employee._id === employeeRecord.employee._id ? employeeRecord : item)
            }
        },
        deleteTimeRecord(state, action) {
            const { time } = action.payload;
            const times = state.employeeRecord.times.filter(item => item.timeId !== time.timeId);
            const summary = calculateEmployeeSummary(state.entityDetail.fromDate, times);
            const employeeRecord = {
                ...state.employeeRecord,
                ...summary,
                times
            };
            state.employeeRecord = employeeRecord;
            state.entityDetail = {
                ...state.entityDetail,
                employeeRecords: state.entityDetail.employeeRecords.map(item => item.employee._id === employeeRecord.employee._id ? employeeRecord : item)
            }
        },
        updateTimeRecord(state, action) {
            const { time } = action.payload;
            const times = state.employeeRecord.times.map(item => item.timeId === time.timeId ? time : item);
            const summary = calculateEmployeeSummary(state.entityDetail.fromDate, times);
            const employeeRecord = {
                ...state.employeeRecord,
                ...summary,
                times
            };
            state.employeeRecord = employeeRecord;
            state.entityDetail = {
                ...state.entityDetail,
                employeeRecords: state.entityDetail.employeeRecords.map(item => item.employee._id === employeeRecord.employee._id ? employeeRecord : item)
            };
        },
        updateEmployeePayment(state, action) {
            const { payType, salary, rates, ira } = action.payload;
            const employeeRecord = {
                ...state.employeeRecord,
                payType,
                salary,
                rates,
                ira,
            };
            state.employeeRecord = employeeRecord;
            state.entityDetail = {
                ...state.entityDetail,
                employeeRecords: state.entityDetail.employeeRecords.map(item => item.employee._id === employeeRecord.employee._id ? employeeRecord : item)
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPayPeriodList.fulfilled, (state, action) => {
                state.entities = action.payload;
            })
            .addCase(fetchPayPeriodById.pending, (state, action) => {
                state.status = 'loading';
                state.currentRequestId = action.meta.requestId;
            })
            .addCase(fetchPayPeriodById.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.currentRequestId === requestId) {
                    state.status = 'ready';
                    state.entityDetail = action.payload;
                    state.currentRequestId = undefined;
                }
            })
            .addCase(fetchPayPeriodById.rejected, (state, action) => {
                const { requestId } = action.meta;
                if (state.currentRequestId === requestId) {
                    state.status = 'error';
                    state.entityDetail = null;
                    state.currentRequestId = undefined;
                }
            })
            .addCase(createPayPeriod.fulfilled, (state, action) => {
                const id = action.payload._id;
                state.entities = [
                    action.payload,
                    ...state.entities.filter(entity => entity._id !== id)
                ];
            })
            .addCase(updatePayPeriod.fulfilled, (state, action) => {
                const id = action.payload._id;
                state.entities = [
                    action.payload,
                    ...state.entities.filter(entity => entity._id !== id)
                ];
            })
            .addCase(deletePayPeriod.fulfilled, (state, action) => {
                state.entities = state.entities.filter(entity => entity._id !== action.payload);
            });
    }
});

export const selectPayPeriods = (state) => state.payPeriod.entities;

export const selectPayperiodDetail = (state) => state.payPeriod.entityDetail;
export const selectEmployeeRecord = (state) => state.payPeriod.employeeRecord;
export const selectTimeRecord = (state) => state.payPeriod.timeRecord;

export const {
    fetchEmployeeRecord,
    fetchTimeRecord,
    addTimeRecord,
    deleteTimeRecord,
    updateTimeRecord,
    updateEmployeePayment,
} = payPeriodSlice.actions;

export default payPeriodSlice.reducer;

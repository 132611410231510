import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { userContext } from '../../../hooks/userContext';

function SettingsPage() {
    const navigate = useNavigate();
    const { profile } = useContext(userContext);

    useEffect(() => {
        if (!profile) {
            navigate("/login", { state: { to: "/settings" }});
        }
    }, [profile, navigate]);

    return (
        <Container maxWidth="xl">
            <Typography
                component="h1"
                variant="h5"
                color="text.primary"
                gutterBottom
            >
                Settings
            </Typography>
            <div>Placeholder for settings</div>
        </Container>
    );
}

export default SettingsPage;

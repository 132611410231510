import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Formatter from '../../../utils/Formatter';

function PayPeriodInfo({ payPeriod }) {
    return (
        <Box sx={{ paddingBottom: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h6">
                        From
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        {Formatter.formatDate(payPeriod.fromDate)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6">
                        To
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        {Formatter.formatDate(payPeriod.toDate)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6">
                        Payroll Date
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                    {Formatter.formatDate(payPeriod.payrollDate)}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PayPeriodInfo;

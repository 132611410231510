import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

function PayPeriodList({ payPeriods, onPayPeriodClick }) {
    const handlePayPeriodClick = (payPeriod) => () => {
        if (onPayPeriodClick) {
            onPayPeriodClick(payPeriod);
        }
    };

    return (
        <List disablePadding>
            {payPeriods.map(payPeriod => (
                <ListItem
                    key={payPeriod._id}
                    divider
                    dense
                    disableGutters
                    secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="Edit"
                            onClick={handlePayPeriodClick(payPeriod)}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    }
                >
                    <ListItemButton
                        disableGutters
                        onClick={handlePayPeriodClick(payPeriod)}
                    >
                        <ListItemText
                            primary={payPeriod.title}
                            secondary={`Status: ${payPeriod.status}`}
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
}

export default PayPeriodList;

import _ from 'lodash';
import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import TimesheetAPI from '../api/timesheetAPI';

export const EMPLOYEE_STATUSES = {
    active: 'active',
    inactive: 'inactive'
};

export const PAYTYPES = {
    salary: 'salary',
    hour: 'hour'
};

export const NEW_EMPLOYEE_TEMPLATE = {
    employeeId: '',
    firstName: '',
    lastName: '',
    payType: PAYTYPES.hour,
    salary: null,
    rates: 15.50,
    ira: 0,
    status: EMPLOYEE_STATUSES.active,
    ssn: '',
    address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
    },
    w4: {
        filingStatus: '',
        claim: 0
    },
    memo: ''
};

const initialState = {
    entities: [],
    status: 'idle',
    error: null,
};

const sortEntities = (entities) => _.sortBy(entities, ['employeeId']);

export const fetchEmployeeList = createAsyncThunk('employee/fetchEmployeeList', async ({ filter, sort }) => {
    const response = await TimesheetAPI.fetchEmployees({ filter, sort });
    const result = await response.json();
    if (!result.success) {
        return isRejectedWithValue(result);
    }
    return result.data;
});

export const createEmployee = createAsyncThunk('employee/createEmployee', async ({ data }) => {
    const response = await TimesheetAPI.createEmployee(data);
    const result = await response.json();
    if (!result.success) {
        return isRejectedWithValue(result);
    }
    return result.data;
});

export const updateEmployee = createAsyncThunk('employee/updateEmployee', async ({ data }) => {
    const response = await TimesheetAPI.updateEmployee(data);
    const result = await response.json();
    if (!result.success) {
        return isRejectedWithValue(result);
    }
    return result.data;
});

export const deleteEmployee = createAsyncThunk('employee/deleteEmployee', async ({ id }) => {
    await TimesheetAPI.deleteEmployee(id);
    return id;
});

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmployeeList.fulfilled, (state, action) => {
                state.entities = sortEntities(action.payload);
            })
            .addCase(createEmployee.fulfilled, (state, action) => {
                state.entities.push(action.payload);
                state.entities = sortEntities(state.entities);
            })
            .addCase(updateEmployee.fulfilled, (state, action) => {
                state.entities = state.entities.map(entity => entity._id === action.payload._id ? action.payload : entity);
                state.entities = sortEntities(state.entities);
            })
            .addCase(deleteEmployee.fulfilled, (state, action) => {
                state.entities = state.entities.filter(entity => entity._id !== action.payload);
            });
    }
});

export const selectEmployees = (state) => state.employee.entities;

export default employeeSlice.reducer;

import React, { useState, useEffect } from 'react';
import {
    TextField,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Paper,
} from '@mui/material';
import AddressInput from './AddressInput';
import W4Input from './W4Input';

function EmployeeForm({ employee, onChange }) {
    const [value, setValue] = useState(employee);

    useEffect(() => {
        if (onChange) {
            onChange(value);
        }
    }, [value, onChange]);

    return (
        <Paper elevation={0}>
            <TextField
                id="employeeId"
                label="Employee ID"
                fullWidth
                required
                value={value.employeeId}
                onChange={(e) => setValue({ ...value, employeeId: e.target.value })}
                margin="dense"
            />
            <TextField
                id="firstName"
                label="First name"
                fullWidth
                required
                value={value.firstName}
                onChange={(e) => setValue({ ...value, firstName: e.target.value })}
                margin="dense"
            />
            <TextField
                id="lastName"
                label="Last name"
                fullWidth
                required
                value={value.lastName}
                onChange={(e) => setValue({ ...value, lastName: e.target.value })}
                margin="dense"
            />
            <FormControl fullWidth>
                <FormLabel id="status">Status</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="status"
                    name="status"
                    value={value.status}
                    onChange={(e) => setValue({ ...value, status: e.target.value })}
                    >
                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                    <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                </RadioGroup>
            </FormControl>
            <FormControl fullWidth>
                <FormLabel id="payType">Pay Type</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="payType"
                    name="payType"
                    value={value.payType}
                    onChange={(e) => setValue({ ...value, payType: e.target.value })}
                    >
                    <FormControlLabel value="salary" control={<Radio />} label="Salary" />
                    <FormControlLabel value="hour" control={<Radio />} label="Hour" />
                </RadioGroup>
            </FormControl>
            {(value.payType === 'salary') && (
                <TextField
                    id="salary"
                    label="Salary"
                    fullWidth
                    value={value.salary}
                    onChange={(e) => { setValue({ ...value, salary: e.target.value }) }}
                    margin="dense"
                    type="number"
                    inputProps={{ min: 0, inputMode: 'numeric' }}
                />
            )}
            {(value.payType === 'hour') && (
                <TextField
                    id="rates"
                    label="Rates per hour"
                    fullWidth
                    value={value.rates}
                    onChange={(e) => { setValue({ ...value, rates: e.target.value }) }}
                    margin="dense"
                    type="number"
                    inputProps={{ min: 0, inputMode: 'numeric' }}
                />
            )}
            <TextField
                id="ira"
                label="IRA"
                fullWidth
                value={value.ira}
                onChange={(e) => { setValue({ ...value, ira: e.target.value }) }}
                margin="dense"
                type="number"
                inputProps={{ min: 0, inputMode: 'numeric' }}
            />
            <TextField
                id="ssn"
                label="SSN"
                fullWidth
                required
                value={value.ssn}
                onChange={(e) => { setValue({ ...value, ssn: e.target.value }) }}
                margin="dense"
            />
            <AddressInput address={value.address} onChange={(address) => setValue({ ...value, address })} />
            <W4Input w4={value.w4} onChange={(w4) => setValue({ ...value, w4 })} />
            <TextField
                id="memo"
                label="Memo"
                fullWidth
                multiline
                rows={4}
                value={value.content}
                onChange={(e) => { setValue({ ...value, content: e.target.value }) }}
                margin="normal"
            />
        </Paper>
    );
}

export default EmployeeForm;

import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
    Outlet,
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import Layout from '../Layout';
import LoginPage from '../../pages/LoginPage';
import TimesheetListPage from '../../pages/TimesheetPage';
import TimesheetDetailPage from '../../pages/TimesheetPage/TimesheetDetailPage';
import TimesheetReviewPage from '../../pages/TimesheetPage/TimesheetReviewPage';
import EmployeePage from '../../pages/EmployeePage/EmployeePage';

import SettingsPage from '../../pages/SettingsPage';
import { fetchEmployeeList } from '../../../store/employeeSlice';
import { fetchPayPeriodList, fetchPayPeriodById } from '../../../store/payPeriodSlice';

const basename = process.env.REACT_APP_BASENAME || '/';

const getRouter = (dispatch) => createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                element: <div>Welcome, Dashboard</div>,
            },
            {
                path: 'login',
                element: <LoginPage />,
            },
            {
                path: 'timesheets',
                element: <Outlet />,
                loader: () => {
                    return null;
                },
                children: [
                    {
                        index: true,
                        element: <TimesheetListPage />,
                        loader: () => {
                            dispatch(fetchPayPeriodList({ filter: null, sort: { toDate: -1 } }));
                            return null;
                        },
                    },
                    {
                        path: ':id',
                        element: <Outlet />,
                        loader: ({ params }) => {
                            dispatch(fetchPayPeriodById(params));
                            dispatch(fetchEmployeeList({ filter: { status: 'active' }, sort: { emplyeeId: 1 } }));
                            return null;
                        },
                        children: [
                            {
                                index: true,
                                element: <TimesheetDetailPage />,
                                loader: () => {
                                    return null;
                                },
                            },
                            {
                                path: 'review',
                                element: <TimesheetReviewPage />,
                                loader: ({ params }) => null,
                            },
                        ],
                    },
                    
                ],
            },
            {
                path: 'employees',
                element: <Outlet />,
                loader: ({ request }) => {
                    dispatch(fetchEmployeeList({ filter: null, sort: { emplyeeId: 1 } }));
                    return null;
                },
                children: [
                    {
                        index: true,
                        element: <EmployeePage />
                    },
                ],
            },
            {
                path: 'settings',
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <SettingsPage />
                    },
                ],
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/" />,
    },
], {
    basename: basename,
});

function Main() {
    const dispatch = useDispatch();

    return (
        <RouterProvider router={getRouter(dispatch)} />
    );
};

export default Main;

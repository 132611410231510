import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@mui/material';

import Formatter from '../../../utils/Formatter';

function EmployeePayDialog({
    open,
    onClose,
    onSubmit,
    employeeRecord,
    ...props
}) {
    const [value, setValue] = useState(employeeRecord);

    const handleClose = () => {
        setValue(employeeRecord);
        if (onClose) {
            onClose();
        }
    };

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit({
                payType: value.payType,
                salary: Number.parseFloat(value.salary),
                rates: Number.parseFloat(value.rates),
                ira: Number.parseFloat(value.ira),
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...props}
        >
            <DialogTitle>
                {Formatter.formatEmployeeName(employeeRecord.employee)}
            </DialogTitle>
            <DialogContent dividers>
                <Box component={'form'}>
                    <FormControl fullWidth>
                        <FormLabel id="payType">Pay Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="payType"
                            name="payType"
                            value={value.payType}
                            onChange={(e) => setValue({ ...value, payType: e.target.value })}
                        >
                            <FormControlLabel value="salary" control={<Radio />} label="Salary" />
                            <FormControlLabel value="hour" control={<Radio />} label="Hour" />
                        </RadioGroup>
                    </FormControl>
                    {(value.payType === 'salary') && (
                        <TextField
                            id="salary"
                            label="Salary"
                            fullWidth
                            value={value.salary}
                            onChange={(e) => { setValue({ ...value, salary: e.target.value }) }}
                            margin="dense"
                            type="number"
                            inputProps={{ min: 0, inputMode: 'numeric' }}
                        />
                    )}
                    {(value.payType === 'hour') && (
                        <TextField
                            id="rates"
                            label="Rates per hour"
                            fullWidth
                            value={value.rates}
                            onChange={(e) => { setValue({ ...value, rates: e.target.value }) }}
                            margin="dense"
                            type="number"
                            inputProps={{ min: 0, inputMode: 'numeric' }}
                        />
                    )}
                    <TextField
                        id="ira"
                        label="IRA"
                        fullWidth
                        value={value.ira}
                        onChange={(e) => { setValue({ ...value, ira: e.target.value }) }}
                        margin="dense"
                        type="number"
                        inputProps={{ min: 0, inputMode: 'numeric' }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EmployeePayDialog;

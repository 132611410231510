import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import Toolbar from '@mui/material/Toolbar';
// import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import PageHeader from '../../molecules/PageHeader';
import PayPeriodInfo from './PayPeriodInfo';
import TimesheetEmployeeList from './TimesheetEmployeeList';
// import TimesheetDateList from './TimesheetDateList';
import EmployeeDialog from './EmployeeDialog';
import TimeRecordDialog from './TimeRecordDialog';
import {
    PAY_PERIOD_STATUSES,
    selectPayperiodDetail,
    updatePayPeriod,
    fetchEmployeeRecord,
    selectEmployeeRecord,
    fetchTimeRecord,
    addTimeRecord,
    deleteTimeRecord,
    updateTimeRecord,
    selectTimeRecord,
} from '../../../store/payPeriodSlice';
import { selectEmployees } from '../../../store/employeeSlice';
import { userContext } from '../../../hooks/userContext';

function TimesheetDetailPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { profile } = useContext(userContext);
    useEffect(() => {
        if (!profile) {
            navigate("/login", { state: { to: `/timesheets/${id}` } });
        }
    }, [profile, navigate, id]);

    const employees = useSelector(selectEmployees);
    const payPeriod = useSelector(selectPayperiodDetail);
    const employeeRecord = useSelector(selectEmployeeRecord);
    const timeRecord = useSelector(selectTimeRecord);

    const [viewMode, setViewMode] = useState("employee");

    // const handleViewModeChange = (event, newViewMode) => {
    //     if (newViewMode) {
    //         setViewMode(newViewMode);
    //     }
    // };

    const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);

    const handleEmployeeSelected = (employee) => {
        dispatch(fetchEmployeeRecord({ employee }));
        setEmployeeDialogOpen(true);
    };

    const handleEmployeeDialogClose = () => {
        setEmployeeDialogOpen(false);
    };

    const [timeDialogOpen, setTimeDialogOpen] = useState(false);

    const handleSelectTime = (time) => {
        dispatch(fetchTimeRecord({ time }));
        setTimeDialogOpen(true);
    };

    const handleAddTime = (inTime, outTime) => {
        dispatch(addTimeRecord({
            inTime: inTime.toISOString(),
            outTime: outTime.toISOString(),
        }));
        setTimeDialogOpen(true);
    };

    const handleDeleteTime = (time) => {
        dispatch(deleteTimeRecord({ time }))
    };

    const handleTimeDialogClose = (time) => {
        setTimeDialogOpen(false);
    };

    const handleTimeDialogChanged = (time) => {
        dispatch(updateTimeRecord({ time }));
        setTimeDialogOpen(false);
    };

    const save = async (next) => {
        await dispatch(updatePayPeriod({ data: payPeriod })).unwrap();

        if (next) {
            next();
        }
    };

    const handleSaveClick = () => {
        save(() => navigate(0));
    };

    const handleReviewClick = () => {
        save(() => navigate('review'));
    };

    if (!payPeriod) {
        return (<div>Loading...</div>);
    }

    return (
        <Container maxWidth="xl" disableGutters>
            <PageHeader title="Pay Period" />
            <PayPeriodInfo payPeriod={payPeriod} />
            <Box sx={{ paddingBottom: 2 }}>
                {/* <Toolbar disableGutters>
                    <ToggleButtonGroup
                        value={viewMode}
                        exclusive
                        fullWidth
                        onChange={handleViewModeChange}
                        aria-label="View Mode"
                    >
                        <ToggleButton value="employee" aria-label="Employees" color="primary">
                            Employees
                        </ToggleButton>
                        <ToggleButton value="date" aria-label="Dates" color="primary">
                            Dates
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Toolbar> */}

                {(viewMode === 'employee') && (
                    <TimesheetEmployeeList
                        payPeriod={payPeriod}
                        employees={employees}
                        onEmployeeSelected={handleEmployeeSelected}
                    />
                )}
                {/* {(viewMode === 'date') && (
                    <TimesheetDateList
                        fromDate={timesheet.fromDate}
                        toDate={timesheet.toDate}
                        records={timesheet.records}
                        onDateSelected={(date) => navigate("date")}
                    />
                )} */}
            </Box>
            {(payPeriod.status === PAY_PERIOD_STATUSES.inProgress) && (
                <Box sx={{ paddingBottom: 2, display: 'flex', justifyContent: 'center', gap: 1 }}>
                    <Button
                        variant="outlined"
                        onClick={handleSaveClick}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleReviewClick}
                    >
                        Review
                    </Button>
                </Box>
            )}
            {employeeRecord && (
                <EmployeeDialog
                    fullScreen
                    open={employeeDialogOpen}
                    onClose={handleEmployeeDialogClose}
                    payPeriod={payPeriod}
                    employeeRecord={employeeRecord}
                    onTimeClick={handleSelectTime}
                    onTimeAddClick={handleAddTime}
                    onTimeDeleteClick={handleDeleteTime}
                />
            )}
            {timeRecord && (
                <TimeRecordDialog
                    // fullScreen
                    fullWidth
                    open={timeDialogOpen}
                    onClose={handleTimeDialogClose}
                    onSubmit={handleTimeDialogChanged}
                    time={timeRecord}
                />
            )}
        </Container>
    );
}

export default TimesheetDetailPage;

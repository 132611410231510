import moment from 'moment';

export const calculateAllDates = (fromDate, toDate) => {
    const dates = [];
    let date = moment(fromDate);
    while (date.isSameOrBefore(toDate)) {
        dates.push(moment(date));
        date.add(1, 'days');
    }
    return dates;
};

import moment from 'moment';

const formatDate = (date) => moment(date).format('ll');
const formatLongDate = (date) => moment(date).format('dddd, ll');
const formatTime = (date) => moment(date).format('h:mm a');

const formatDuration = (duration) => {
    if (!duration) {
        return "-";
    }

    if (!moment.isDuration(duration)) {
        duration = moment.duration(duration, 'minutes');
    }

    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours === 0 && minutes === 0) {
        return "-";
    }

    if (minutes) {
        return `${hours} hr ${minutes} m`;
    }
    return `${hours} hr`;
};

const formatEmployeeName = (employee) => {
    const { firstName, lastName } = employee || {};
    return `${firstName} ${lastName}`;
};

const minutesToHours = (minutes) => (minutes / 60).toFixed(2);

const formatSummary = (summary) => {
    if (!summary) {
        return '-';
    }

    const { duration, tip } = summary;

    let summaryText = '';
    if (duration && (duration.total)) {
        summaryText += `Time: ${minutesToHours(duration.regular)}`;
        if (duration.overtime) {
            summaryText += ` + 1.5x(${minutesToHours(duration.overtime)})`;
        }
        if (duration.overtime2) {
            summaryText += ` + 2x(${minutesToHours(duration.overtime2)})`;
        }
        summaryText += ' hr'
    }
    if (tip) {
        if (summaryText) {
            summaryText += ', ';
        }
        summaryText += `Tip: $${tip}`;
    }
    return summaryText;
};

const Formatter = {
    formatDate,
    formatLongDate,
    formatTime,
    formatDuration,
    formatEmployeeName,
    formatSummary,
    minutesToHours,
};

export default Formatter;

import React, { useState, useEffect } from 'react';
import {
    TextField,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Paper,
} from '@mui/material';

function W4Input({ w4, onChange }) {
    const [value, setValue] = useState(w4);

    useEffect(() => {
        if (onChange) {
            onChange(value);
        }
    }, [value, onChange]);

    return (
        <Paper elevation={0}>
            <FormControl fullWidth>
                <FormLabel id="w4">W-4</FormLabel>
            </FormControl>
            <FormControl fullWidth>
                <FormLabel id="filingStatus">Filing Status</FormLabel>
                <RadioGroup
                    aria-labelledby="filingStatus"
                    name="filingStatus"
                    value={value.filingStatus}
                    onChange={(e) => { setValue({
                        ...value,
                        filingStatus: e.target.value
                    }) }}
                >
                    <FormControlLabel value="single" control={<Radio />} label="Single" />
                    <FormControlLabel value="married" control={<Radio />} label="Married" />
                    <FormControlLabel value="head" control={<Radio />} label="Head of household" />
                </RadioGroup>
            </FormControl>
            <TextField
                id="claim"
                label="Claim"
                fullWidth
                value={value.claim}
                onChange={(e) => { setValue({
                    ...value,
                    claim: e.target.value
                }) }}
                margin="dense"
                type="number"
                inputProps={{ min: 0, inputMode: 'numeric' }}
            />
        </Paper>
    );
}

export default W4Input;

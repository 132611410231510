import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Button,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
} from '@mui/material';
import PageHeader from '../../molecules/PageHeader';
import FormDialog from '../../molecules/FormDialog';
import Search from '../../molecules/Search';
import EmployeeFilter from './EmployeeFilter';
import {
    createEmployee as createItem,
    updateEmployee as updateItem,
    selectEmployees as selectItems,
    NEW_EMPLOYEE_TEMPLATE as NEW_ITEM_TEMPLETE,
} from '../../../store/employeeSlice';
import { userContext } from '../../../hooks/userContext';
import EmployeeForm from './EmployeeForm';

function EmployeePage() {
    const dispatch = useDispatch();
    const entities = useSelector(selectItems);
    const [selectedItem, setSelectedItem] = useState({});

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const navigate = useNavigate();
    const { profile } = useContext(userContext);
    useEffect(() => {
        if (!profile) {
            navigate("/login", { state: { to: "/employees" } });
        }
    }, [profile, navigate]);

    const [search, setSearch] = useState("");
    const [checkedStatuses, setCheckedStatuses] = useState([ 'active' ]);

    const filters = [
        {
            name: 'status',
            label: 'Filter by Status',
            options: [{ id: 'active', label: 'Active' }, { id: 'inactive', label: 'Inactive' }],
            selected: checkedStatuses,
        },
    ];

    const handleFiltersChange = (event) => {
        const { name, selected } = event.target;
        if (name === 'status') {
            setCheckedStatuses(selected);
        }
    };

    const employees = useMemo(() => {
        const searchTerm = search.toLowerCase();

        const isMatched = (employee) => (employee.firstName.toLowerCase().includes(searchTerm) || employee.lastName.toLowerCase().includes(searchTerm))
            && ((checkedStatuses.length === 0) || checkedStatuses.includes(employee.status));

        return entities.filter(employee => isMatched(employee));
    }, [entities, search, checkedStatuses]);

    const refreshData = () => {
        navigate(0);
    };

    const handleCreateClick = () => {
        setSelectedItem({ ...NEW_ITEM_TEMPLETE });
        setCreateDialogOpen(true);
    };

    const handleCreateDialogClose = () => {
        setCreateDialogOpen(false);
    };

    const handleCreateSave = async (e) => {
        e.preventDefault();
        const newItem = {
            ...selectedItem,
        };
        await dispatch(createItem({ data: newItem })).unwrap();
        setCreateDialogOpen(false);
        refreshData();
    };

    const handleItemClick = (id) => {
        const foundItem = entities.find(item => item._id === id);
        if (foundItem) {
            setSelectedItem(foundItem);
            setEditDialogOpen(true);
        }
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const handleEditSave = (e) => {
        e.preventDefault();
        const updatedItem = {
            ...selectedItem,
        };
        dispatch(updateItem({ data: updatedItem })).then(() => {
            setEditDialogOpen(false);
            refreshData();
        });
    };

    const renderItems = (items) => {
        if (items.length === 0) {
            return null;
        }

        return items.map(item => (
            <ListItem
                key={item._id}
                divider
                disablePadding
            >
                <ListItemButton
                    onClick={() => handleItemClick(item._id)}
                >
                    <ListItemText
                        primary={`${item.employeeId}: ${item.firstName} ${item.lastName}`}
                        secondary={`Status: ${item.status}`}
                    />
                </ListItemButton>
            </ListItem>
        ));
    };

    return (
        <React.Fragment>
            <Container maxWidth="xl" disableGutters>
                <PageHeader title="Employees" disableBack>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleCreateClick}
                    >
                        Add
                    </Button>
                </PageHeader>

                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1 }} />
                    <Search value={search} onChange={(e) => setSearch(e.target.value)} />
                    <EmployeeFilter
                        filters={filters}
                        onFiltersChange={handleFiltersChange}
                    />
                </Toolbar>

                {(entities.length === 0) && (
                    <p>
                        <i>No Data</i>
                    </p>
                )}
                {(entities.length > 0) && (
                    <React.Fragment>
                        {renderItems(employees)}
                    </React.Fragment>
                )}
            </Container>

            <FormDialog
                open={createDialogOpen}
                onClose={handleCreateDialogClose}
                onSave={handleCreateSave}
                title={'Create Employee'}
            >
                <Box component={'form'} onSubmit={handleCreateSave}>
                    <EmployeeForm employee={selectedItem} onChange={(value) => setSelectedItem(value)} />
                </Box>
            </FormDialog>

            <FormDialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                onSave={handleEditSave}
                title={'Edit Employee'}
            >
                <Box component={'form'} onSubmit={handleEditSave}>
                    <EmployeeForm employee={selectedItem} onChange={(value) => setSelectedItem(value)} />
                </Box>
            </FormDialog>
        </React.Fragment>
    );
}

export default EmployeePage;

import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import { CSVLink } from 'react-csv';

import PageHeader from '../../molecules/PageHeader';
import PayPeriodInfo from './PayPeriodInfo';
import TimesheetEmployeeReviewList from './TimesheetEmployeeReviewList';
import {
    selectPayperiodDetail,
    updatePayPeriod,
    fetchEmployeeRecord,
    selectEmployeeRecord,
    updateEmployeePayment,
    PAY_PERIOD_STATUSES,
} from '../../../store/payPeriodSlice';
import { updateEmployee, PAYTYPES } from '../../../store/employeeSlice';
import { userContext } from '../../../hooks/userContext';
import EmployeePayDialog from './EmployeePayDialog';
import Formatter from '../../../utils/Formatter';

function TimesheetReviewPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { profile } = useContext(userContext);
    useEffect(() => {
        if (!profile) {
            navigate("/login", { state: { to: `/timesheets/${id}` } });
        }
    }, [profile, navigate, id]);

    const payPeriod = useSelector(selectPayperiodDetail);
    const employeeRecord = useSelector(selectEmployeeRecord);

    const [employeePayDialogOpen, setEmployeePayDialogOpen] = useState(false);

    const handleEmployeeSelected = (employee) => {
        dispatch(fetchEmployeeRecord({ employee }));
        setEmployeePayDialogOpen(true);
    };

    const handleEmployeePayDialogClose = () => {
        setEmployeePayDialogOpen(false);
    };

    const handleEmployeePayDialogSubmit = (value) => {
        const save = async () => {
            const employeePayment = {
                _id: employeeRecord.employee._id,
                ...value,
            };
            await dispatch(updateEmployee({ data: employeePayment })).unwrap();

            setEmployeePayDialogOpen(false);
        };

        dispatch(updateEmployeePayment(value));
        save();
    };

    const handleSaveClick = (value) => {
        const save = async () => {
            await dispatch(updatePayPeriod({
                data: {
                    ...payPeriod,
                }
            })).unwrap();
        };
        save();
    };

    const handleDoneClick = () => {
        // update status to done, save, then navigate to listing page
        const save = async () => {
            await dispatch(updatePayPeriod({
                data: {
                    ...payPeriod,
                    status: PAY_PERIOD_STATUSES.done
                }
            })).unwrap();

            navigate('/timesheets');
        };

        save();
    };

    const csvData = useMemo(() => {
        const getOvertimeText = (employeeRecord) => {
            let text = '';
            if (employeeRecord.duration.overtime) {
                text += Formatter.formatDuration(employeeRecord.duration.overtime);
            }
            if (employeeRecord.duration.overtime2) {
                text += ` 2x ${Formatter.formatDuration(employeeRecord.duration.overtime2)}`;
            }
            return text;
        };

        return [
            ["Season Thai Cuisine"],
            ["Employee's Timesheet"],
            ["Payroll Date", Formatter.formatDate(payPeriod.payrollDate)],
            ["From", Formatter.formatDate(payPeriod.fromDate)],
            ["To", Formatter.formatDate(payPeriod.toDate)],
            [],
            ["ID #", "First Name", "Last Name", "Salary", "Rate/Hour", "Hour/2wk", "Overtime", "Tips Cash", "Simple IRA"],
            ...payPeriod.employeeRecords.map(employeeRecord => (
                [
                    employeeRecord.employee.employeeId,
                    employeeRecord.employee.firstName,
                    employeeRecord.employee.lastName,
                    (employeeRecord.payType === PAYTYPES.salary) ? employeeRecord.salary : '',
                    (employeeRecord.payType === PAYTYPES.hour) ? employeeRecord.rates : '',
                    (employeeRecord.payType === PAYTYPES.hour) ? Formatter.formatDuration(employeeRecord.duration.regular) : '',
                    (employeeRecord.payType === PAYTYPES.hour) ? getOvertimeText(employeeRecord) : '',
                    employeeRecord.tip,
                    employeeRecord.ira
                ]
            )),
        ];
    }, [payPeriod]);

    if (!payPeriod) {
        return (<div>Loading...</div>);
    }

    return (
        <Container maxWidth="xl" disableGutters>
            <PageHeader title="Review Pay Period">
                <CSVLink
                    data={csvData}
                    filename="payroll.csv"
                >
                    <IconButton
                        size="sm"
                    >
                        <IosShareIcon />
                    </IconButton>
                </CSVLink>
            </PageHeader>
            <PayPeriodInfo payPeriod={payPeriod} />

            <Box sx={{ paddingBottom: 2 }}>
                <TimesheetEmployeeReviewList
                    payPeriod={payPeriod}
                    onEmployeeSelected={handleEmployeeSelected}
                />
            </Box>
            <Box sx={{ paddingBottom: 2, display: 'flex', gap: 1, justifyContent: 'center' }}>
                <Button
                    variant="outlined"
                    onClick={handleSaveClick}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    onClick={handleDoneClick}
                >
                    Done
                </Button>
            </Box>
            {employeeRecord && (
                <EmployeePayDialog
                    fullWidth
                    open={employeePayDialogOpen}
                    onClose={handleEmployeePayDialogClose}
                    onSubmit={handleEmployeePayDialogSubmit}
                    employeeRecord={employeeRecord}
                />
            )}
        </Container>
    );
}

export default TimesheetReviewPage;

import React, { useMemo } from 'react';
import moment from 'moment';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';

import Formatter from '../../../utils/Formatter';
import { calculateAllDates } from '../../../utils/DateUtils';
import { PAY_PERIOD_STATUSES } from '../../../store/payPeriodSlice';

function EmployeeDialog({
    open,
    onClose,
    payPeriod,
    employeeRecord,
    onTimeClick,
    onTimeAddClick,
    onTimeDeleteClick,
    ...props
}) {
    const dates = useMemo(() => calculateAllDates(payPeriod.fromDate, payPeriod.toDate), [payPeriod]);

    const handleEditTime = (time) => {
        if (payPeriod.status !== PAY_PERIOD_STATUSES.inProgress) {
            return;
        }

        if (onTimeClick) {
            onTimeClick(time)
        }
    };

    const handleAddTime = (inTime, outTime) => {
        if (onTimeAddClick) {
            onTimeAddClick(inTime, outTime);
        }
    }

    const handleDeleteTime = (time) => {
        if (onTimeDeleteClick) {
            onTimeDeleteClick(time);
        }
    }

    const renderRecord = (record) => {
        if (payPeriod.status !== PAY_PERIOD_STATUSES.inProgress) {
            return (
                <ListItem
                    key={record.timeId}
                    divider
                    dense
                    disableGutters
                >
                    <ListItemText
                        primary={`${Formatter.formatTime(record.inTime)} - ${Formatter.formatTime(record.outTime)} (${Formatter.formatDuration(moment.duration(record.durationMinutes, 'minutes'))})`}
                        secondary={`Tip: $${record.tip}`}
                    />
                </ListItem>
            );
        }

        return (
            <ListItem
                key={record.timeId}
                divider
                dense
                disableGutters
                secondaryAction={
                    <IconButton
                        edge="end"
                        aria-label="Delete"
                        onClick={() => handleDeleteTime(record)}
                    >
                        <ClearIcon />
                    </IconButton>
                }
            >
                <ListItemButton
                    disableGutters
                    onClick={() => handleEditTime(record)}
                >
                    <ListItemText
                        primary={`${Formatter.formatTime(record.inTime)} - ${Formatter.formatTime(record.outTime)} (${Formatter.formatDuration(moment.duration(record.durationMinutes, 'minutes'))})`}
                        secondary={`Tip: $${record.tip}`}
                    />
                </ListItemButton>
            </ListItem>
        );
    };

    const renderDate = (date) => {
        const { times } = employeeRecord;
        const records = times.filter(record => moment(record.inTime).isSame(date, 'day'));

        const inAMTime = moment(date).set('hour', 10).set('minute', 0);
        const outAMTime = moment(date).set('hour', 14).set('minute', 0);

        const inPMTime = moment(date).set('hour', 16).set('minute', 0);
        const outPMTime = moment(date).set('hour', 20).set('minute', 0);

        return (
            <Card key={date.toISOString()} sx={{ width: '100%', marginBottom: 1 }}>
                <CardHeader
                    sx={{ paddingBottom: 0 }}
                    avatar={
                        <Avatar>{moment(date).format('dd')}</Avatar>
                    }
                    title={moment(date).format('MMMM D, YYYY')}
                />
                {(records.length > 0) && (
                    <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <List disablePadding>
                            {records.map(record => renderRecord(record))}
                        </List>
                    </CardContent>
                )}
                <CardActions>
                    <Button
                        size="small"
                        onClick={() => handleAddTime(inAMTime, outAMTime)}
                    >
                        Add AM
                    </Button>
                    <Button
                        size="small"
                        onClick={() => handleAddTime(inPMTime, outPMTime)}
                    >
                        Add PM
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const renderSummary = () => (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: 1, zIndex: 10 }}>
            {Formatter.formatSummary(employeeRecord)}
        </Paper>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...props}
        >
            <DialogTitle>
                <IconButton
                    size="sm"
                    onClick={onClose}
                >
                    <ArrowBackIcon />
                </IconButton>
                {Formatter.formatEmployeeName(employeeRecord.employee)}
            </DialogTitle>
            <DialogContent dividers>
                <Box component={'form'}>
                    <Grid container spacing={0}>
                        {dates.map((date) => renderDate(date))}
                    </Grid>
                    {renderSummary()}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default EmployeeDialog;

import { doGet, doPost, doPatch, doDelete } from './BaseAPI';
// import { doGet, doPost, doPatch, doDelete } from './MockAPI';

const DEFAULT_LIMIT = 1000;

// Employees
export const fetchEmployees = async ({ filter, sort }) => {
    let queryString = '';
    if (filter) {
        queryString += `&filter=${JSON.stringify(filter)}`;
    }
    if (sort) {
        queryString += `&sort=${JSON.stringify(sort)}`;
    }
    return doGet(`/employee?limit=${DEFAULT_LIMIT}${queryString}`);
};

export const fetchEmployeeById = async (id) => doGet(`/employee/${id}`);

export const createEmployee = async (data) => doPost('/employee', data);

export const updateEmployee = async (data) => doPatch('/employee', data);

export const deleteEmployee = async (id) => doDelete('/employee', id);

// Pay Periods
export const fetchPayPeriods = async ({ filter, sort }) => {
    let queryString = '';
    if (filter) {
        queryString += `&filter=${JSON.stringify(filter)}`;
    }
    if (sort) {
        queryString += `&sort=${JSON.stringify(sort)}`;
    }
    return doGet(`/payPeriod?limit=${DEFAULT_LIMIT}${queryString}`);
};

export const fetchPayPeriodById = async (id) => doGet(`/payPeriod/${id}`);

export const createPayPeriod = async (data) => doPost('/payPeriod', data);

export const updatePayPeriod = async (data) => doPatch('/payPeriod', data);

export const deletePayPeriod = async (id) => doDelete('/payPeriod', id);

// Time Records
export const fetchTimeRecords = async (filter) => {
    const queryString = filter ? `filter=${JSON.stringify(filter)}` : '';
    return doGet(`/timeRecord?limit=${DEFAULT_LIMIT}&${queryString}`);
};

export const fetchTimeRecordById = async (id) => doGet(`/timeRecord/${id}`);

export const createTimeRecord = async (data) => doPost('/timeRecord', data);

export const updateTimeRecord = async (data) => doPatch('/timeRecord', data);

export const deleteTimeRecord = async (id) => doDelete('/timeRecord', id);

// Timesheets
export const fetchTimesheets = async (filter) => {
    const queryString = filter ? `filter=${JSON.stringify(filter)}` : '';
    return doGet(`/timesheet?limit=${DEFAULT_LIMIT}&${queryString}`);
};

export const fetchTimesheetById = async (id) => doGet(`/timesheet/${id}`);

export const createTimesheet = async (data) => doPost('/timesheet', data);

export const updateTimesheet = async (data) => doPatch('/timesheet', data);

export const deleteTimesheet = async (id) => doDelete('/timesheet', id);

const TimesheetAPI = {
    fetchEmployees, fetchEmployeeById, createEmployee, updateEmployee, deleteEmployee,
    fetchPayPeriods, fetchPayPeriodById, createPayPeriod, updatePayPeriod, deletePayPeriod,
    fetchTimeRecords, fetchTimeRecordById, createTimeRecord, updateTimeRecord, deleteTimeRecord,
    fetchTimesheets, fetchTimesheetById, createTimesheet, updateTimesheet, deleteTimesheet,
};

export default TimesheetAPI;

import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from '@mui/material';

import PayPeriodList from './PayPeriodList';
import NewPayPeriodDialog from './NewPayPeriodDialog';
import PageHeader from '../../molecules/PageHeader';
import { selectPayPeriods, createPayPeriod } from '../../../store/payPeriodSlice';
import { userContext } from '../../../hooks/userContext';

function TimesheetListPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { profile } = useContext(userContext);
    useEffect(() => {
        if (!profile) {
            navigate("/login", { state: { to: "/timesheets" } });
        }
    }, [profile, navigate]);

    const payPeriods = useSelector(selectPayPeriods);

    const [newDialogOpen, setNewDialogOpen] = useState(false);

    const handleNewPeriodClick = () => {
        setNewDialogOpen(true);
    };

    const handleNewPeriodDialogClose = () => {
        setNewDialogOpen(false);
    };

    const handleNewPeriodDialogSubmit = ({ fromDate, toDate, payrollDate }) => {
        dispatch(createPayPeriod({ fromDate, toDate, payrollDate }))
            .unwrap()
            .then((result) => {
                setNewDialogOpen(false);
                navigate(result._id);        
            })
            .catch((error) => {
                console.error('error: ', error);
            });
    };

    return (
        <Container maxWidth="xl" disableGutters>
            <PageHeader title="Timesheets" disableBack>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleNewPeriodClick}
                >
                    Add
                </Button>
            </PageHeader>
            <PayPeriodList
                payPeriods={payPeriods}
                onPayPeriodClick={(payPeriod) => navigate(payPeriod._id)}
            />

            <NewPayPeriodDialog
                fullWidth
                open={newDialogOpen}
                onClose={handleNewPeriodDialogClose}
                onSubmit={handleNewPeriodDialogSubmit}
            />
        </Container>
    );
}

export default TimesheetListPage;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Box, Button, DialogActions, FormControl, InputAdornment, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import Formatter from '../../../utils/Formatter';

function TimeRecordDialog({
    open,
    onClose,
    onSubmit,
    time,
    ...props
}) {
    const [inTime, setInTime] = useState(moment());
    const [outTime, setOutTime] = useState(moment());
    const [breakMinutes, setBreakMinutes] = useState(0);
    const [tip, setTip] = useState(0);

    const resetForm = useCallback(() => {
        setInTime(moment(time.inTime));
        setOutTime(moment(time.outTime));
        setBreakMinutes(time.breakMinutes);
        setTip(time.tip);
    }, [time]);

    useEffect(() => {
        resetForm();
    }, [time, resetForm]);

    const duration = useMemo(() => moment.duration(outTime.diff(inTime)).subtract(breakMinutes, 'minutes'), [inTime, outTime, breakMinutes]);

    const handleClose = () => {
        resetForm();
        if (onClose) {
            onClose();
        }
    };

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit({
                ...time,
                inTime: inTime.toISOString(),
                outTime: outTime.toISOString(),
                breakMinutes: Number.parseInt(breakMinutes),
                durationMinutes: duration.asMinutes(),
                tip: Number.parseFloat(tip),
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            {...props}
        >
            <DialogTitle>
                {Formatter.formatLongDate(inTime)}
            </DialogTitle>
            <DialogContent dividers>
                <Box component={'form'}>
                    <FormControl margin="dense" fullWidth>
                        <DesktopTimePicker
                            label="In"
                            views={['hours', 'minutes']}
                            value={inTime}
                            onChange={(value) => setInTime(value)}
                        />
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <DesktopTimePicker
                            label="Out"
                            views={['hours', 'minutes']}
                            value={outTime}
                            onChange={(value) => setOutTime(value)}
                        />
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <TextField
                            label="Break (minutes)"
                            value={breakMinutes}
                            onChange={(e) => setBreakMinutes(e.target.value)}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onFocus={(e) => { e.target.select(); }}
                        />
                    </FormControl>
                    <div>Duration: {Formatter.formatDuration(duration)}</div>
                    <FormControl margin="dense" fullWidth>
                        <TextField
                            label="Tip"
                            value={tip}
                            onChange={(e) => setTip(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                            }}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onFocus={(e) => { e.target.select(); }}
                        />
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default TimeRecordDialog;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function NewPayPeriodDialog({
    open,
    onSubmit,
    onClose,
    from,
    to,
    payroll,
    ...props
}) {
    const today = moment().hour(0).minute(0).second(0);
    const [fromDate, setFromDate] = useState(moment(today));
    const [toDate, setToDate] = useState(moment(today).add(14, 'day'));
    const [payrollDate, setPayrollDate] = useState(moment(today).add(18, 'day'));

    useEffect(() => {
        const newFromDate = moment(toDate).add(-13, 'day');
        setFromDate(newFromDate);
        const newPayrollDate = moment(toDate).add(3, 'day');
        setPayrollDate(newPayrollDate);
    }, [toDate]);

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit({
                fromDate,
                toDate,
                payrollDate,
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...props}
        >
            <DialogTitle>New Pay-Period</DialogTitle>
            <DialogContent dividers>
                <Box component={'form'}>
                    <FormControl margin="dense" fullWidth>
                        <DatePicker
                            label="From Date"
                            inputFormat="MM/DD/YYYY"
                            value={fromDate}
                            onChange={setFromDate}
                            slotProps={{ textField: { margin: 'normal' } }}
                        />
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <DatePicker
                            label="To Date"
                            inputFormat="MM/DD/YYYY"
                            value={toDate}
                            onChange={setToDate}
                            slotProps={{ textField: { margin: 'normal' } }}
                        />
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <DatePicker
                            label="Payroll Date"
                            inputFormat="MM/DD/YYYY"
                            value={payrollDate}
                            onChange={setPayrollDate}
                            slotProps={{ textField: { margin: 'normal' } }}
                        />
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewPayPeriodDialog;

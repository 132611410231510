import React from 'react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Formatter from '../../../utils/Formatter';
import { PAY_PERIOD_STATUSES } from '../../../store/payPeriodSlice';

function TimesheetEmployeeList({ payPeriod, employees = [], onEmployeeSelected }) {
    const { status, employeeRecords } = payPeriod;

    const handleEmployeeSelected = (employee) => () => {
        if (onEmployeeSelected) {
            onEmployeeSelected(employee);
        }
    };

    const renderEmployeeRecord = (employeeRecord) => {
        const { employee } = employeeRecord;

        return (
            <ListItem
                key={employee._id}
                divider
                dense
                disableGutters
                secondaryAction={
                    <IconButton
                        edge="end"
                        aria-label="Edit"
                        onClick={handleEmployeeSelected(employee)}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                }
            >
                <ListItemButton
                    disableGutters
                    onClick={handleEmployeeSelected(employee)}
                >
                    <ListItemText
                        primary={Formatter.formatEmployeeName(employee)}
                        secondary={Formatter.formatSummary(employeeRecord)}
                    />
                </ListItemButton>
            </ListItem>
        );
    };

    const renderActiveEmployees = () => {
        const empWithDataIds = employeeRecords.map(employeeRecord => employeeRecord.employee._id);
        const noDataEmployees = employees.filter(employee => !empWithDataIds.includes(employee._id));
        return noDataEmployees.map(employee => renderEmployeeRecord({ employee }))
    };

    return (
        <List disablePadding>
            {employeeRecords.map(employeeRecord => renderEmployeeRecord(employeeRecord))}
            {(status === PAY_PERIOD_STATUSES.inProgress) && renderActiveEmployees()}
        </List>
    );
}

export default TimesheetEmployeeList;
